import React, { useState } from "react"
import styles from "../styles/global.module.scss"

export default function CareerForm({ }) {
  // Initialize local state
  let [localState, setLocalState] = useState({
    name: "",
    phone: "",
    email: "",
    position: "",
    resume: "",
  })

  // Update state on form field change
  const onChange = e => {
    setLocalState({
      ...localState,
      [e.target.name]: e.target.value,
    })
  }

  return (
    <form
      className={styles.careerForm}
      name="Careers"
      method="POST"
      netlify-honeypot="bot-field"
      data-netlify="true"
      action="/thank-you/"
    >
      <input type="hidden" name="form-name" value="Careers" />
      <input type="hidden" name="bot-field" />
      <div className={styles.formItem}>
        <label className={styles.hidden} htmlFor="your-name">
          Your Name
        </label>
        <input
          id="your-name"
          type="text"
          name="name"
          placeholder="Your Name *"
          onBlur={e => onChange(e)}
          onChange={e => onChange(e)}
          required
        />
      </div>
      <div className={styles.formItem}>
        <label className={styles.hidden} htmlFor="phone">
          Phone Number
        </label>
        <input
          id="phone"
          type="phone"
          name="phone"
          placeholder="Phone Number *"
          onBlur={e => onChange(e)}
          onChange={e => onChange(e)}
          required
        />
      </div>
      <div className={styles.formItem}>
        <label className={styles.hidden} htmlFor="email">
          Your Email
        </label>
        <input
          id="email"
          type="email"
          name="email"
          placeholder="Your Email *"
          onBlur={e => onChange(e)}
          onChange={e => onChange(e)}
          required
        />
      </div>
      <div className={styles.formItem}>
        <label className={styles.hidden} htmlFor="position">
          Position Applying To
        </label>
        <select
          id="position"
          type="select"
          name="position"
          placeholder="Position Applying To"
          onBlur={e => onChange(e)}
          onChange={e => onChange(e)}
          required
        >
          <option selected disabled className={styles.disabled} value="select-plceholder">Position Applying To</option>
          <option value="web-developer-intern">Web Developer Intern</option>
          <option value="sales-rep">Sales Representative</option>
        </select>
      </div>
      <div className={styles.formItem}>
      <label htmlFor="resume">
          Attach Resume
        </label>
        <input
          id="resume"
          type="file"
          name="resume"
          placeholder="Attach Resume"
          onBlur={e => onChange(e)}
          onChange={e => onChange(e)}
          required
          class="hidden"
        />
      </div>
      <div className={styles.btnContain}>
        <button className={styles.btn} type="submit" aria-label="Send">
          Send
        </button>
      </div>
    </form>
  )
}
