import React, { useEffect } from "react"
import { Link } from "gatsby"
import styles from "@one-builder/gatsby-theme-ob-master/src/styles/global.module.scss"

// Components
import Layout from "../components/layout/layout"
import CareerForm from "../components/CareerForm"
import { useGetTokens } from "@one-builder/gatsby-theme-ob-master/src/hooks/useGetTokens"
import convertTokens from "@one-builder/gatsby-theme-ob-master/src/helpers/convertTokens"
import Image from "@one-builder/gatsby-theme-ob-master/src/components/common/image"
import { Accordion, AccordionItem } from 'react-light-accordion';
import 'react-light-accordion/demo/css/index.css';
import {
  AiOutlineLaptop,
  AiOutlineCode,
  AiOutlineClockCircle,
} from "react-icons/ai"
import Flip from "react-reveal/Flip"

export default ({ location }) => {
  useEffect(() => {
    let body = document.querySelector("body")
    if (body && !body.classList.contains("perks")) {
      body.classList.add("perks")
    } else {
      body.classList.remove("perks")
    }
  }, [])

  // Get metadata
  const metadata = useGetTokens()

  let seo_data = {
    title: `Careers | {{CompanyName}}`,
    description: `Scranton based digital marketing agency looking for talented individuals to join our team.`,
    page_visible: true,
  }

  return (
    <Layout location={location} is_landing={false} seo_data={seo_data}>
      <div className={styles.careers}>
        <Image
          alt="Customer Acquisition Specialist"
          filename="careers-hero.jpg"
        />
        <div className={styles.container}>
          <h1>Join Our Team</h1>
          <p>
            At One Builder Co. we use the latest technology to create great
            experiences not only for our clients, but our employees as well. We
            believe every day should offer an opportunity for excellence and
            allow our team to embrace their creative minds. Our team work where
            they want, when they want. This allows us to achieve the highest
            quality work as possible. Good work inspires us all to be better and
            stretch our creative boundaries.
          </p>
          <p>Join our team and make an impact today.</p>
          <Link className={styles.primaryBtn} to="#openings-positions">
            View Openings
          </Link>
        </div>
        <section className={styles.perks}>
          <div className={styles.container}>
            <div className={styles.inner}>
              <Flip left cascade>
                <div className={styles.sub}>
                  <AiOutlineLaptop />
                  <h3>Always Remote</h3>
                  <p>
                    Work in your own, comfortable environment. We use Slack and
                    G Suite for our communications.
                  </p>
                </div>
                <div className={styles.sub}>
                  <AiOutlineCode />
                  <h3>Latest Technology</h3>
                  <p>
                    Our websites are built with React and GatsbyJs. We also use
                    a suite of marketing tools to help our clients win.
                  </p>
                </div>
                <div className={styles.sub}>
                  <AiOutlineClockCircle />
                  <h3>Choose Your Schedule</h3>
                  <p>
                    Creative minds run better at different times. Work when you
                    feel productive.
                  </p>
                </div>
              </Flip>
            </div>
          </div>
        </section>
        <section className={styles.application}>
          <div className={styles.wrapper}>
          <div id="openings-positions" className={styles.inner}>
            <h1>Open Positions</h1>
            <p>Click the position titles to learn more.</p>
            <Accordion atomic={true}>
            <AccordionItem  title="Web Developer Intern">
              <p>
                We are looking for a web developer intern to help us with our client websites. Our platform is built on the JAMstack, more specifically GatsbyJS, so React experience is essential.
              </p>
              <p><strong>Required Experience:</strong><br />
              - Javascript<br />
              - React<br />
              - Static Site Generators (GatsbyJs)<br />
              - SCSS<br />
              - Using Slack for communication<br />
              </p>
              <p>If you are interested in this position, please fill out the form and we will get back to you as soon as possible.</p>
            </AccordionItem >
            <AccordionItem  title="Sales Representative">
              <p><strong>Compensation</strong>: Generous Commission</p>
              <p>
              Are you someone that loves to partner businesses with the right solutions for success? Great. You will totally fit right in. Welcome. At OneBuilder, we help people and construction businesses win. And we do that by building great relationships and partnering these businesses with the right solutions.
              </p>
              <p>This is an important role, therefore, you should be pleasant both in-person and on the phone, enjoy building relationships, and a self-starter with focus on driving solutions and adding value. You will be selling marketing services such as website development, SEO services, and digital marketing services.</p>
              <p>This position has the opportunity to be highly lucrative and could change from a 1099 contractor to a W2 employee with great benefits.</p>
              <p><strong>Responsibilities</strong>: You are responsible for managing and executing an outbound calling effort as well as building relationships to establish affiliate/partner sales opportunities.<br />
              - Generate leads, qualify leads, and build a funnel of sales opportunities<br />
              - Meet goals<br />
              - Intelligently demo products<br />
              - Track and maintain prospect and customer activity<br />
              - Attend trade shows, events, and seminars when necessary<br />
              - Create proposals for prospects
              </p>
              <p><strong>Requirements:</strong><br />
              - A relationship builder with a strong passion to help people win <br />
              - Considerate and values others<br />
              - B2B selling experience a plus<br />
              - Fun, honest, and social<br />
              - Excited about helping a company grow<br />
              - Good person with heart of kindness<br />
              - WORK REMOTELY
              </p>
              <p><strong>Benefits:</strong> No Benefits. Independent Contractor</p>
              <p>If you are interested in this position, please fill out the form and we will get back to you as soon as possible.</p>
            </AccordionItem >
            </Accordion>

          </div>
          <div className={styles.inner}>
            <CareerForm />
          </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}
